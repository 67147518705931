import { LncBaseApiClientService } from "@odw/lnc-services/js-services/api-client/lnc-base-api-client.service";

/**
 * Service pour réaliser les appels à l'api entreprise client.
 */
export default class MesPrmsEntrepriseService extends LncBaseApiClientService {

  /**
   * Action pour exporter les prms delestes de l'utilisateur
   * @param cnAlex {string} L'identifiant alex de la personne.
   * @param listSirens {void|*} Liste des SIRENs associée aux prms à exporter
   * @returns {Promise<TacheDto[]>} La promesse d'une réponse.
   */
  exportPrmsDelestes(cnAlex, listSirens, dateFormated) {
    return new Promise((resolve, reject) => {
      this._customPostRequestWithHeader("/api/private/v1/personnes/" + cnAlex + "/demande-prm-delestes", {
        sirens: listSirens, // liste des sirens (doit être un tableau de chaînes de 9 chiffres)
        dateDemande: dateFormated // date en format string
      }).then((response) => {
        if (response.status === 204) {
          resolve([])
        } else {
          resolve(response.content);
        }
      }).catch(() => {
        reject();
      });
    });
  }

  /**
   * Action pour envoyer une ressource au format json avec la méthode POST.
   * @param path {string} Le chemin vers le service à appeler,
   * le chemin doit commencer par une barre oblique
   * @param data {*} La ressource qui sera envoyée au format json.
   * @returns {Promise<BaseApiClientResponse>} La promesse d'une réponse à l'action POST.
   */
  _customPostRequestWithHeader(path, data) {
    return new Promise((resolve, reject) => {
      jQuery
        .ajax({
          ...{
            crossDomain: true,
            xhrFields: {
              withCredentials: true,
            }
          },
          url: this.baseUrl + path,
          method: 'POST',
          data: JSON.stringify(data),
          contentType: 'application/json',
          headers: {
            ...({ 'X-Context-ID': 'PB2B' }),
            ...(this.xsrfEnabled && { 'X-XSRF-TOKEN': this.getXsrfToken() })
          },
        })
        .done((response, textStatus, jqXHR) => {
          resolve({ content: response, status: jqXHR.status });
        })
        .fail(response => {
          console.error(response);
          reject(response);
        });
    });
  }
}
